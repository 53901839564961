@import "_variables";

/* Fonts */
/*
	font-family: $Roboto;
	font-family: $RobotoCondensed;
	font-family: $RobotoSlab;
	font-family: $Playfair;
*/

/* Media Screens */
.container {
 width: 970px;
 margin: 0 auto;
	body & {
		@include break(large) {
			width: 1270px;
		}
	}
  @include break(desktop) {
		width: 1170px;
	}
	@include break(neutral) {
		width: 721px;
	}
	@include break(mobile) {
		width: 100%;
	}
	@include break(x-small) {
		width: 100%;
		min-width: 320px;
	}
}
html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: #160c05;
}
body {
	@include fz(16px, 1.5em, #4a4a4a, 400);
	font-family: $Roboto;
	background: url("../img/bg.jpg") 50% top no-repeat;
	@include break(medium) {
		font-size: 15px;
	}
	@include break(x-small) {
		min-width: 320px;
		font-size: 14px;
	}
	&::after {
		content:'';
		display: block;
		position: absolute;
		top:0;
		left:0;
		background-color: rgba(27, 14, 2, 0.43);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.72);
    height: 11px;
    z-index: 10;
    width: 100%;
	}
}
.site-container {
	max-width: 1300px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	background-color: rgba(0, 0, 0, 0.58);
	border-left: 1px #70573c dashed;
	border-right: 1px #70573c dashed;
	&::before {
		content:'';
		display: block;
		position: absolute;
		top:0;
		left:100%;
    height: 100%;
    z-index: 8;
    width: 100%;
		background: url("../img/right.png") left top no-repeat;
	}
	&::after {
		content:'';
		display: block;
		position: absolute;
		top:0;
		right:100%;
    height: 100%;
    z-index: 8;
    width: 100%;
		background: url("../img/left.png") right top no-repeat;
	}

	@include break(custom) {
		max-width: 1200px;
	}
	@include break(medium) {
		max-width: 1000px;
	}
	@include break(neutral) {
		max-width: 740px;
	}
	@include break(mobile) {
		max-width: 100%;
	}
}



/**   nav menu  **/

#nav {
	border-top: 1px #666251 solid;
	float: right;
	@include break(medium) {
		float: none;
		width: 100%;
		clear: both;
		text-align: center;		
	}
	@include break(mobile) {
		display: none;
	}
	@include clearfix();

	.sf-menu {
		position: relative;
		font-size: 0;
		line-height: 0;
		@include break(custom) {
		}
		@include break(medium) {
		}

		& > li {
			margin: 0;
			position: relative;
			display: inline-block;
			font-size: 0;
			line-height: 0;
			padding: 0 14px;
			@include break(neutral) {
				padding: 0 9px;
			}
			& > a {
				@include fz(18px, 1.4, #ebe2b4, 400);
				text-decoration: none;
				display: inline-block;
				font-family: $RobotoCondensed;
				font-stretch: condensed;
				text-transform: uppercase;				
				border-bottom: 2px transparent solid;
				padding: 16px 0 4px;
				@include break(custom) {
					font-size: 16px;
				}
				@include break(medium) {
				}
				@include break(neutral) {
					font-size: 13px;
				}
			}


			&.sfHover,
			&:hover { 
				& > a {
					color: #e0bc46;
				}
			}  
			& > a.current-menu-item {
				color: #e0bc46;
			}
		}
		i {
			font-family:'FontAwesome';
			font-style: normal;			
			margin-left: -10px;
			@include break(neutral) {
				margin-left: 0;
			}		
			@include break(mobile) {
				display: none;				
			}
			& + em {
				display: none;
				@include break(mobile) {
					display: block;				
				}				
			}
		}
		span {
			display: none;			
		}

		.has-menu {			
			em {
				font-family:'FontAwesome';
				font-style: normal;			
				top: 28px;
				right:-2px;
				display: block;
				position: absolute;
				width: 10px;
				height: 10px;
				font-size: 14px;
				color: #ebe2b4;
				@include break(medium) {
					display: none;				
				}
				@include break(mobile) {
					display: none!important;				
				}

			}
			&:hover {
				a {
					border-bottom-color: #e6c657 ;
				}
			}
		}
		ul.dd_menu {
			position: absolute;
			min-width: 280px;
			left:-2px;
			top: 100%;
			background: rgba(0, 0, 0, .8);
			padding: 12px 8px 6px; 
			z-index: 100;
			border-radius: 5px;
			display: none;			
			text-align: left;
			
			li {
				padding: 2px 0;
				a {
					display: block;
					text-decoration: none;
					@include fz(15px, 1.4, #c7be94, 400);
					padding: 2px 12px;
					@include break(medium) {
						font-size: 12px;
					}
					&:hover {
						color: #fffbe2;
						background: #573c23;
					}
				}
			}
			li + li {
				border-top: 1px #543f2c dashed;
			}
		}
	}
}




/**     H E A D E R  **/
header.site-header {
	padding: 10px 0;
	position: relative;
	z-index: 1000;
	@include break(neutral) {
	}
	@include break(mobile){
		margin-top: 40px;
	}
	#wrapper {
		position: relative;
		z-index: 2;
		padding-top: 10px;
	  @include break(neutral) {
	  	padding-top: 0;
		}
	  @include break(mobile) {
		}
	}
	.phone {
		float: right;
		padding-left: 25px;
		padding-right: 20px;
	  @include break(neutral) {
			padding-left: 5px;
			padding-right: 5px;
			padding-top: 5px;
		}
	  @include break(mobile) {
	  	position: fixed;
	  	left:10px;
	  	top: 5px;
	  	z-index: 1100;
		}
		a {
			@include fz(20px, 1.4, #e6c657, 700);
			font-family: $RobotoCondensed;
			text-decoration: none;			
		  @include break(neutral) {
		  	font-size: 14px;
			}
		  @include break(mobile) {
		  	font-size: 16px;
			}
		}
		i {
			font-family:'FontAwesome';
			@include fz(20px, 1.4, #e6c657, 400);
			font-style: normal;			
			margin-top: 2px;
			margin-right: 5px;
		  @include break(neutral) {
		  	font-size: 14px;
			}
		  @include break(mobile) {
		  	font-size: 16px;
			}

		}
	}
	.address {
		float: right;
		padding-top: 6px;
		padding-right: 20px;
	  @include break(neutral) {
	  	padding-top: 8px;
		}
	  @include break(mobile) {
	  	float: none;
	  	text-align: center;
	  	width: 100%;
	  	padding-right: 0;
		}
		p {
			@include fz(16px, 1.2, #c7be94, 400);
		  @include break(neutral) {
		  	font-size: 14px;
			}

		}
		i {
			font-family:'FontAwesome';
			font-style: normal;			
			margin-right: 8px;
			color: #e6c657;
			font-size: 20px;
			margin-top: -2px;
		}
	}
	.socials {
		float: right;
		padding-left: 15px;
		padding-bottom: 13px;
	  @include break(neutral) {
	  	display: none;	  	
		}
	}

	.logo {
		position: relative;
		float: left;
		margin-left: -10px;
	  @include break(custom) {
	  	width: 260px;
	  	margin-top: 2px;
		}
	  @include break(medium) {
	  	width: 200px;
	  	margin-top: -15px;
		}
	  @include break(neutral) {
	  	width: 160px;
	  	margin-top: -8px;
		}

	  @include break(mobile) {
	  	float: none;
	  	margin-left: auto;
	  	margin-right: auto;
	  	display: block;	  	
	  	width: 220px;
	  	padding-top: 15px;
		}
	  @include break(x-small) {
		}
	}

}


/* E N D  H E A D E R */

.socials {
	li {
		float: left;
		padding-left: 12px;
		@include break(neutral) {
			padding-left: 5px;
		}		

		a {
			font-family:'FontAwesome';
			@include fz(20px, 1.4, #c7be94, 400);
			text-decoration: none;
			&:hover {
				color: #e6c657;
			}			
		}
	}
}


/**     S L I D E R        **/

#sub-page {
 #slider 	{
 	img.logo {
 		width: 120px;
 		top: auto;
 		bottom:0;
 		left:40px;
		@include break(mobile) {
			left:10px;
			bottom:-40px;
			width: 100px;
		}
 	}
 }
}

#slider {
	background: #70573c url("../img/border.png") 0 0 no-repeat;
	background-size: 100% 100%;
	padding: 11px ;
	position: relative;	
	overflow: hidden;	
	@include break(medium) {
		padding: 8px;
	}
	@include break(mobile) {
		padding: 4px;
	}



	img.logo {
		position: absolute;
		left:100px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 100;
		display: block;		
	  @include break(custom) {
	  	left:60px;
	  	width: 200px;
		}
	  @include break(neutral) {
	  	left:20px;
	  	width: 120px;
		}
	}

	.flex-control-nav.flex-control-paging {
		position: absolute;
		bottom: 30px;
		width: 100%;
		left:0;
		z-index: 101;
		text-align: center;		
	  @include break(neutral) {
	  	bottom: 10px;
		}
	  @include break(mobile) {
	  	display: none;	  	
		}

		li {
			display: inline-block;
			text-indent: -9999px;
			padding: 0 6px;
			a {
				display: block;
				background-color: #ebe2b4;
  			box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
  			opacity: .8;
  			width: 10px;
  			height: 10px;
 				border-radius: 50%;
  			&:hover,
  			&.flex-active {
  				background: #000;
  				width: 12px;
  				height: 12px;
  				border: 2px #fff solid;
  				margin-top: -1px;
  				margin-left: -1px;
  				margin-right: -1px;
  			}
			}
		}
	}

	.flex-direction-nav {
		position: absolute;
		left:0;
		top: 0;
		width: 100%;
		z-index: 100;
		height: 100%;
		opacity: .01;
		@include link_trans();
		li {
			display: block;
			background: rgba(0, 0, 0, .5);
			width: 42px;
			height: 100%;
			@include link_trans();
			a {
				display: block;
				width: 42px;
				height: 100%;
				background: url("../img/arrow-slider.svg") 50% 50% no-repeat;
			}
			&:hover {
				background: rgba(0, 0, 0, .75);
			}
			&.flex-nav-prev {
				float: left;		
				a {
					transform: rotate(180deg);
				}
			}
			&.flex-nav-next {
				float: right;				
			}
		}
	}

	&:hover {
		ul.flex-direction-nav {
			opacity: 1;
		}
	}

	.slides {
		li {
			display: none;			
		}
	}

}
.flexslider {
	position: relative;
	overflow: hidden;	
}

.slogan {
	text-align: center;
	padding: 15px 0 0;
	@include break(medium) {
		padding: 8px 0 0;
		margin-bottom: 0;
	}
	img {
		display: inline-block;
		@include break(medium) {
			max-width: 80%;
		}
		@include break(mobile) {
			max-width: 92%;
		}
	}
}

hr {
	margin: 10px 0 ;
	padding: 0;
	background: url("../img/line.jpg") 50% 50% no-repeat;
	height: 6px;
	border: 0;
}

/**     C  O  N  T  E  N  T         **/
.content {
	margin-bottom: 15px;
	padding-top: 10px;
	h1 {
		margin: 0 0 30px;
		padding: 0 0 25px 0;
		@include fz(36px, 1.4em, #1b0b01, 400);
		text-align: center;
		font-family: $Playfair;		
		position: relative;
		@include break(medium) {
			font-size: 28px;
		}
	  @include break(neutral) {
			font-size: 20px;
			padding-bottom: 15px;
			margin-bottom: 20px;
		}
	  @include break(x-small) {
			font-size: 18px;
		}

		&::after {
			display: block;
			content: '';
			width: 120px;
			height: 7px;
			border-bottom: 2px #5b4334 solid;
			border-top: 3px #5b4334 solid;
			left:50%;
			bottom:0;
			position: absolute;			
			transform: translateX(-50%);
		}
	}
	h2 {
		margin: 20px 0 10px;
		padding: 10px 0 5px 0;
		@include fz(30px, 1.4em, #1b0b01, 400);
		text-align: left;
		font-family: $Playfair;		
		position: relative;
		@include break(medium) {
			font-size: 28px;
		}
	  @include break(neutral) {
			font-size: 20px;
			padding-bottom: 15px;
			margin-bottom: 20px;
		}
	  @include break(x-small) {
			font-size: 18px;
		}

		
	}
	h3 {
		margin: 10px 0 5px;
		padding: 10px 0 10px 0;
		@include fz(24px, 1.4em, #000, 400);
		text-align: left;
		font-family: $Playfair;		
		position: relative;
		@include break(medium) {
			font-size: 20px;
		}
	  @include break(neutral) {
			font-size: 18px;
		}
	  @include break(x-small) {
			font-size: 15px;
		}

	}
	h4 {
		margin: 0;
		padding: 0 0 15px 0;
		font-family: $RobotoSlab;
		@include fz(16px, 1.4em, #1b0b01, 700);
	}
	h5 {
		margin: 0;
		padding: 0 0 15px 0;
		font-family: $RobotoSlab;
		@include fz(16px, 1.4em, #1b0b01, 700);
		text-align: center;		
		@include break(medium) {
			font-size: 14px;
			br {
				display: none;				
			}
		}
	  @include break(x-small) {
	  	font-weight: 400;
		}
	}
}
.paper {
	background: #fefee9 url("../img/paper.jpg") 50% top repeat-x;
	padding: 40px 30px 10px;
	min-height: 650px;
  @include break(neutral) {
  	padding:  20px 15px 5px;
	}
  @include break(x-small) {
  	padding:  20px 8px 5px;
	}

	p {
		font-family: $RobotoSlab;
		@include fz(16px, 1.5, #1b0b01, 400);
	  @include break(neutral) {
	  	font-size: 14px;
		}
	}

	p.call {
		text-transform: uppercase;
		text-align: center;
		@include fz(30px, 1.4em, #5b4334, 700);
		font-family: $RobotoCondensed;
		font-stretch: condensed;
		padding: 15px 0;
	  @include break(neutral) {
	  	font-size: 18px;
		}
		@include break(x-small) {
			font-size: 16px;
		}		
		a {
			color: #5b4334;
		}
	}

}
.photos-line {
	overflow: hidden;
	padding: 20px 0 20px;
	text-align: justify;	
	.photo + .photo {
		margin-left: 1%;
	}
	.photo {
		max-width: 32%;
	}
	a {
		&:hover {
			.photo {
				border-color:#e6c657;
			}
		}
	}
	a + a {
		margin-left: 1%;
	}

}
.photos-line + .photos-line {
	padding-top: 0;
}
.photo {
	border: 8px #bfab92 solid;	
	display: inline-block;
	margin-bottom: 10px;
	max-width: 50%;
	@include break(medium) {
		border-width: 4px;
	}		
	@include break(x-small) {
		max-width: 31.5%;
	}		
}


.content-photo {
	float: left;
	margin-bottom: 20px;
	margin-right: 25px;
	border: 8px #bfab92 solid;	
	max-width: 32%;
	@include break(x-small) {
		max-width: 100%;
		margin-right: 0;
	}		
}
.testimonials {
	em {
		color: #000;
		text-align: right;
		font-size: 12px;
		display: block;
		line-height: 18px;
		margin-bottom: 15px;
	}
}

.left_20 {
	margin-left: 20px;
	display: block;	
	@include break(mobile) {

	}		
}
.right_20 {
	margin-right: 20px;
	display: block;	
	@include break(mobile) {

	}		
}

.galleries {
	a {
		display: block;
		text-decoration: none;
		margin-bottom: 25px;
		img {
			margin-bottom: 10px;
			border: 8px #bfab92 solid;	
			display: block;			
		}
		p {
			text-transform: uppercase;			
			text-align: center;			
		}
		&:hover {
			p {
				color:#e6c657;
				text-decoration: underline;								
			}
		}
	}
}


/**     S I D E  B A R          **/

.sidebar-menu {
	@include break(mobile) {
		display: none;		
	}		
	li {
		a {
			@include fz(20px, 1.4em, #c7be94, 400);
			font-family: $RobotoCondensed;
			font-stretch: condensed;
			text-decoration: none;			
			text-transform: uppercase;
			&:hover {
				color: #fffbe2;
			}			
			@include break(medium) {
				font-size: 15px;
			}		
		}
		em {
			display: none;			
		}
		ul {
			padding: 8px 0;
			display: none;			
			li {
				a {
					@include fz(16px, 1.4em, #c7be94, 400);
					text-transform: capitalize;
					padding-left: 14px;					
					display: block;
					position: relative;
					@include break(medium) {
						font-size: 13px;
					}		
					@include break(neutral) {
						padding-left: 5px;
					}

					&::after {
						display: block;
						width: 3px;
						height: 6px;
						background: #928858;
						content: '';
						position: absolute;
						left:0px;
						top: 8px;
						@include break(neutral) {
							display: none;							
						}
					}
					&:hover {
						&::after {
							background: #e6c657;
						}
					}
				}
			}
		}
	}

	a.current-menu-item {
		font-weight: 700;
		color: #fffbe2;
	}

}

.banners {
	text-align: center;
	padding: 15px 0;
	img {
		padding: 10px 0;
		@include break(mobile) {
			max-width: 31.5%;
			margin-left: .5%;
			margin-right: .5%;
		}		
	}
	& + h6 {
		@include break(medium) {
			font-size: 13px;
		}		
	}
}
.right {
	float: right;
	@include break(mobile) {
		float: none;
	}			
}
a.right.left_20 {
	@include break(mobile) {
		float: right;
	}				
}

/**     F O O T E R        F O O T E R         F O O T E R         **/
footer.site-footer {
	.row-footer {
		padding: 40px 0;
		overflow: hidden;		
		@include break(mobile) {
			padding: 20px 0;
			text-align: center;			
			& > .col-sm-3 {
				overflow: hidden;
				padding-top: 15px;				
			}
		}		
		@include break(x-small) {
			padding-top: 0;
		}		


	}
	a.logo-footer {
		width: 200px;
		display: block;
		@include break(medium) {
			width: 140px;
		}		
		@include break(mobile) {
			width: 120px;
			margin-left: auto;
			margin-right: auto;
			display: block;			
		}		

	}
	p {
		@include fz(16px, 1.4em, #c7be94, 400);		
		@include break(medium) {
			font-size: 13px;
		}		
		@include break(mobile) {
			font-size: 12px;
		}		

	}
	strong {
		a {
			display: block;
			@include fz(30px, 1.4em, #bab282, 400);		
			font-family: $RobotoCondensed;
			text-decoration: none;
			margin-bottom: 15px;						
			@include break(medium) {
				font-size: 24px;
			}		
			@include break(mobile) {
				font-size: 20px;
			}		
		}
	}
	a.mail {
		border: solid 1px #fffbe7;
		display: block;
		@include fz(16px, 1.4em, #ebe2b4, 400);	
		text-decoration: none;
		font-family: $RobotoCondensed;
		padding: 8px 24px;
		float: left;			
		@include break(mobile) {
			float: none;
			text-align: center;			
		}		
		&:hover {
			color: #090402;
			background: #e6c657;
			border-color:#e6c657;
		}
	}
	.socials {
		padding-left: 20px;
		padding-top: 4px;
		float: left;
		@include break(medium) {
			padding-left: 5px;
		}		
		@include break(neutral) {
			padding-left: 0;
			padding-top: 10px;
		}
		@include break(mobile) {
			float: none;
			text-align: center;			
			padding-top: 15px;
			padding-bottom: 10px;
			li {
				float: none;
				display: inline-block;
			}
		}		
	}
	.wrapp {
		margin-left: -60px;
		@include break(medium) {
			margin-left: -50px;
		}		
		@include break(neutral) {
			margin-left: 0;
		}		
	}	

/*-------------------    Copyright  --------------------*/
	.copyright {
		border-top: 1px #5d4b37 solid;
		padding: 20px 0 20px;
		overflow: hidden;		
		img {
			display: block;
			float: right;
			margin-left: 20px;
			@include break(mobile) {
				margin-left: 10px;
			}		
		}
		p {
			color: #726e5d;
			@include break(x-small) {
				width: 100%;
				float: none!important;
				text-align: center;
				&.pull-right {
					width: 170px;
					margin-left: auto;
					margin-right: auto;					
				}				
			}		

		}
		a {
			color: #bab282;
		}
	}	
}

h6 {
	@include fz(16px, 1.4em, #fffce8, 700);
	font-family: $RobotoCondensed;
	text-transform: uppercase;
	margin: 0 0 10px 0;
	padding: 0;	
	@include break(medium) {
		font-size: 14px;
	}		
	@include break(mobile) {
		font-size: 16px;
	}		
}

.paddcontent {
	padding-bottom: 32px;
	@include break(mobile) {
		padding-bottom: 15px;
	}		
}

/* Buttons */
.btn:active, .btn:focus,
.btn {
	@include link_trans();
	@include fz(16px, 1.4em, #000, 700);
	margin: 0;
	text-decoration: none;
	background: none;
	position: relative;
	z-index: 10;
	display: inline-block;
 	padding: 12px 20px;
	border-radius: 0px;
	text-align: center;
	background: #e6c657;
	text-transform: uppercase;
	text-shadow:none;
	border: 1px #70573c solid; 
	&:hover {
		color: #70573c;
		border-color: #70573c;
		background: #fffbe7;
	}
	
	@include break(medium) {
	}
	@include break(neutral) {
		font-size: 14px;
	}
	@include break(mobile) {
		font-size: 12px;
	}
	@include break(x-small) {	}
}






/**     F O R M  C O N T A C T   P A G E    **/
#contact-form {

	#ajax-contact-form textarea,
	#ajax-contact-form select,
	#ajax-contact-form input {
		padding: 5px 12px 4px;
		background: none;
		width: 100%;
		border-radius: 0;
		@include fz(13px, 1.4em, #000, 300);
		margin: 0 0 6px;
	  border: solid 1px #5c5947;
		font-family: $RobotoCondensed;
		font-stretch: condensed;
	}

	#ajax-contact-form textarea:active,
	#ajax-contact-form input:active,
	#ajax-contact-form select:active,
	#ajax-contact-form select:focus,
	#ajax-contact-form textarea:focus,
	#ajax-contact-form input:focus {
		box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.12);
	  border: solid 1px #000;
		color: #000;
		outline: none;
	}
	#ajax-contact-form textarea {	height: 160px; }
	#ajax-contact-form *::-webkit-input-placeholder {color:#000; font-family: $Roboto; }
	#ajax-contact-form *::-moz-placeholder          {color:#000; font-family: $Roboto; }/* Firefox 19+ */
	#ajax-contact-form *:-moz-placeholder           {color:#000; font-family: $Roboto; }/* Firefox 18- */
	#ajax-contact-form *:-ms-input-placeholder      {color:#000; font-family: $Roboto; }

	#ajax-contact-form *:focus::-webkit-input-placeholder {color:#000; font-family: $Roboto; }
	#ajax-contact-form *:focus::-moz-placeholder          {color:#000; font-family: $Roboto; }/* Firefox 19+ */
	#ajax-contact-form *:focus:-moz-placeholder           {color:#000; font-family: $Roboto; }/* Firefox 18- */
	#ajax-contact-form *:focus:-ms-input-placeholder      {color:#000; font-family: $Roboto; }


	#button_submit,
	#button_submit:focus,
	#fields .submit {
		@include link_trans();
		@include fz(16px, 1.4em, #000, 700);
		margin: 4px 0 0 0;
		text-decoration: none;
		background: none;
		position: relative;
		z-index: 10;
	 	padding: 10px 20px;
		border-radius: 0;
		text-align: center;
		background: none;
		float: right;
		border:1px #70573c solid;
		width: 160px;
		@include break(medium) {
		}
		@include break(neutral) {
			max-width: 240px;
			margin-left: auto;
			margin-right: auto;
			float: none;
		}
		@include break(mobile) {
		}
		@include break(x-small) {
		}
		&:hover {
			color: #090402;
	    background: #e6c657;
	    border-color: #e6c657;
    }
	}

}

/**     F O R M   S I D E B A R   **/

#ajax-contact-form {
	text-align: left;
	padding-bottom: 20px;
	p {
		margin-bottom: 0;
		padding-bottom: 0;
	}
	label {
		margin-bottom: 0;
	}

}
#ajax-contact-form textarea,
#ajax-contact-form select,
#ajax-contact-form input {
	padding: 5px 12px 4px;
	background: none;
	width: 100%;
	border-radius: 0;
	@include fz(13px, 1.4em, #c7be94, 300);
	margin: 0 0 6px;
  border: solid 1px #5c5947;
	font-family: $RobotoCondensed;
	font-stretch: condensed;
}

#ajax-contact-form textarea:active,
#ajax-contact-form input:active,
#ajax-contact-form select:active,
#ajax-contact-form select:focus,
#ajax-contact-form textarea:focus,
#ajax-contact-form input:focus {
	box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #d2d2d2;
	color: #fff;
	outline: none;
}
#ajax-contact-form textarea {	height: 160px; }
#ajax-contact-form *::-webkit-input-placeholder {color:#c7be94; font-family: $Roboto; }
#ajax-contact-form *::-moz-placeholder          {color:#c7be94; font-family: $Roboto; }/* Firefox 19+ */
#ajax-contact-form *:-moz-placeholder           {color:#c7be94; font-family: $Roboto; }/* Firefox 18- */
#ajax-contact-form *:-ms-input-placeholder      {color:#c7be94; font-family: $Roboto; }

#ajax-contact-form *:focus::-webkit-input-placeholder {color:#c7be94; font-family: $Roboto; }
#ajax-contact-form *:focus::-moz-placeholder          {color:#c7be94; font-family: $Roboto; }/* Firefox 19+ */
#ajax-contact-form *:focus:-moz-placeholder           {color:#c7be94; font-family: $Roboto; }/* Firefox 18- */
#ajax-contact-form *:focus:-ms-input-placeholder      {color:#c7be94; font-family: $Roboto; }


#button_submit,
#fields .submit {
	@include link_trans();
	@include fz(16px, 36px, #ebe2b4, 700);
	font-family: $RobotoCondensed;
	font-stretch: condensed;	
	margin: 4px 0 0 0;
	text-decoration: none;
	background: none;
	position: relative;
	z-index: 10;
	display: inline-block;
 	padding: 2px 8px;
	border-radius: 0;
	text-align: center;
	background: none;
	width: 120px;
	border:1px #fffbe7 solid;

	@include break(medium) {
		font-size: 14px;
	}
	@include break(neutral) {
		max-width: 240px;
		margin-left: auto;
		margin-right: auto;
		float: none;
	}
	@include break(mobile) {
		line-height: 20px;
	}
	@include break(x-small) {
		font-size: 11px;
	}
	&:hover {
		color: #090402;
		background: #e6c657;
		border-color:#e6c657;
	}
}
.notification_ok {
	padding-bottom: 15px;
	color: #000;
	line-height: 1.5em;
	font-size: 18px;
	text-align: left;
	color: #27ade3;
	padding-top: 15px;
}

.notification_error {
	color: #e54423;
	clear: both;
	text-align: left!important;
	font-size: 12px;
	line-height: 1.5em;
	margin-top: -10px;
	font-weight: 700;
}
#note {
	clear: both;
}
#rc-imgelect, .g-recaptcha {
	width: 250px;
  transform:scale(0.82);
  transform-origin:0 0;
  float: left;
	@include break(custom){
	  transform:scale(0.74);
	  transform-origin:0 0;
	}
	@include break(medium){
	  transform:scale(0.60);
	  transform-origin:0 0;
	}
	@include break(neutral){
	  transform:scale(.8);
	  transform-origin:0 0;
	}
	@include break(x-small){
		float: none;
	}

}
img {
		height: auto;
		max-width:100%;
	}
.clearfix {
	clear:both;
}
.center {
	text-align: center;
	margin-left: auto;
	margin-right: auto;	
}

#ajax-contact-form label.error {
	color: #f00;
	font-size: 12px;
	margin-top: -8px;
	display: inline-block;
	font-weight: 400;
	clear:both;
}

.mobile-none {
	@include break(neutral){
		display: none;
	}
}
div#button_submit {
	padding-left: 10px;
	padding-right: 10px;
	font-size: 12px;
}

/**     mobile menu       mobile menu   mobile menu      mobile menu        **/

//colors
$color_0: rgba(255, 255, 255, 0.75);
$color_btn: #222;
$color_text: #fff;
$color_shadow: #000;
$color_hover_bg: #ccc;
$color_hover_text: #222;

.slicknav_menu {
	font-size: 16px;
	box-sizing: border-box;
	background: #160c05;
	display: none;
	z-index: 200;
	position: fixed;
	top: 0;
	width: 100%;
	left:0;
	border-bottom: 1px #70573c dashed;
	@include break(mobile){
		display: block;
	}
	.phone-mobile {
		@include fz(16px, 28px, #fff, 300);
		float: left;
		padding-left: 10px;
		padding-top: 6px;
	  @include break(mobile) {

		}

		a {
			@include fz(16px, 28px, #fff, 300);

		}
		span {
			@include break(x-small){
				display: none;
			}
		}
	}
	* {		box-sizing: border-box;	}
	.slicknav_menutxt {
		color: $color_text;
		font-weight: bold;
		text-shadow: 0 1px 3px $color_shadow;
	}
	.slicknav_icon-bar {
		background-color: #f5841f;
	}
}
.slicknav_nav {
	color: $color_text;
	margin: 0;
	padding: 0;
	font-size: 0.875em;
	list-style: none;
	overflow: hidden;
	background: #160c05;
	z-index:9999;
	.slicknav_row {
		&:click,
		&:active,
		&:focus {
			color: #ebe2b4;
			outline:none;
		}
	}
	a {
			@include fz(13px, 28px, #e0bc46, 400);
			text-align: left;
			text-decoration: none;
			border-top: 1px #70573c dashed;
			padding-bottom: 1px;
			text-transform: capitalize;
			padding-left: 8px;
			@include link_trans();
			&:hover {
				color: #ebe2b4;
			}
		& + i {
			@include break(mobile) {
				display: none;
			}
		}
	}

	a > a {
		border-top: 0;
	}
	.current-menu-item a {		color: #ebe2b4;	}
	.slicknav_txtnode {		margin-left: 15px;	}
	.slicknav_item a {		padding: 0;		margin: 0;	}
	.slicknav_parent-link a {		padding: 0;		margin: 0;	}

	em, i {
		display: none;		
	}
}
.slicknav_brand {
	color: $color_text;
	font-size: 18px;
	line-height: 30px;
	padding: 7px 12px;
	height: 44px;
}
.slicknav_btn {
	span {
		display: none;
	}
	display: block;
	margin: 0;
	padding: 0;
	text-decoration: none;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	background: url("../img/mobile-button.png") 50% 2px no-repeat;
	background-size: 39px auto;
	float: right;
	margin: 0;
	padding: 0;
	top: 0;
	right:10px;
	position: relative;
	@include link_trans();
	&.slicknav_open {
		background-position: 50% -34px;
	}
}

.slicknav_menu {
	*zoom: 1;
	.slicknav_menutxt {
		display: block;
		line-height: 1.188em;
		float: left;
	}
	.slicknav_icon {
		float: left;
    width: 1.125em;
    height: 0.875em;
		margin: 0.188em 0 0 0.438em;
        &:before {
            background: transparent;
            width: 1.125em;
            height: 0.875em;
            display: block;
            content: "";
            position: absolute;
        }
	}
	.slicknav_no-text {
		margin: 0;
	}
	.slicknav_icon-bar {
		display: block;
		width: 1.125em;
		height: 0.125em;
		-webkit-border-radius: 1px;
		-moz-border-radius: 1px;
		border-radius: 1px;
		-webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
		box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
	}
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}
.slicknav_nav {
	clear: both;
	ul {
		list-style: none;
		overflow: hidden;
		padding: 4px 0 4px 0;
		margin: 0;
		border-top: 1px #543f2c dashed;
		background: #573c23;
		li {
			a {
				text-transform: capitalize;
				line-height: 20px;
				color: #c7be94;
				border: none;
				font-size: 13px;
				font-weight: 400;
				padding-left: 15px;
				display: block;
				&:hover {
					color: #fff;
				}
			}
		}
	}
	.slicknav_arrow {
		font-size: 0.8em;
		font-family: 'FontAwesome';
		float: right;
		margin-right: 20px;
	}
	.slicknav_item {
		cursor: pointer;
		a {
			display: inline;
			border-bottom: none;
			&:click,
			&:active,
			&:focus {
				color: #fff;
				outline:none;
			}

		}
	}
	.slicknav_row {
		display: block;
		&:click,
		&:active,
		&:focus {
			color: #fff;
			outline:none;
		}
	}
	a {		display: block;	}
	.slicknav_parent-link a {
		display: inline;
	}
}

/**     O T H E R       **/
a{color:#000; text-decoration:underline;}
a:focus{color:#000;text-decoration:none;}
a:hover{text-decoration:none;color:#000}

.scrolloff {
  pointer-events: none;
}
.map {
	position: relative;
	padding-bottom: 50%;
	padding-top: 30px;
	height: 0;
	background: #ccc;
	margin-bottom: 15px;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: 0;
	}
}
ul.list {
	padding: 0 0 20px 0;
	margin: 0;
	overflow: hidden;
	li {
		padding-left: 25px;
		position: relative;		
		padding-bottom: 5px;
		@include fz(16px, 1.4, #242424, 400);
		&::after {
			position: absolute;
			width: 8px;
			height: 8px;
			content: '';
			border-radius: 50%;
			left: 2px;
			top: 8px;
	    background-color: #70573c;
    }
	}
	&.list-areas {
		li {
			float: left;
			width: 25%;
			@include break(medium) {
				width: 33.3%;
			}
			@include break(neutral) {
				width: 50%;
			}
			@include break(x-small) {
				width: 100%;
			}

		}
	}
}

a[href*="tel"]{text-decoration:none; cursor: default;};
::selection{background: #160c05!important;color:#fff};
::-moz-selection{background: #160c05!important;color:#fff};